import React from "react";

import LayoutLegal from "../components/layoutLegal";
import SEO from "../components/seo";

const IndexPage = () => (
  <LayoutLegal>
    <SEO title="Impressum" />

    <article class="legalContainer">
      <h1>Datenschutzerklärung</h1>

      <p>
        Datenschutzerkl&auml;rung Wir freuen uns sehr &uuml;ber Ihr Interesse an
        unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert
        f&uuml;r die Gesch&auml;ftsleitung von Hirsch Public Relations &ndash;
        Alexander Hirsch. Eine Nutzung der Internetseiten von Hirsch Public
        Relations &ndash; Alexander Hirsch ist grunds&auml;tzlich ohne jede
        Angabe personenbezogener Daten m&ouml;glich. Sofern eine betroffene
        Person besondere Services unseres Unternehmens &uuml;ber unsere
        Internetseite in Anspruch nehmen m&ouml;chte, k&ouml;nnte jedoch eine
        Verarbeitung personenbezogener Daten erforderlich werden. Ist die
        Verarbeitung personenbezogener Daten erforderlich und besteht f&uuml;r
        eine solche Verarbeitung keine gesetzliche Grundlage, holen wir generell
        eine Einwilligung der betroffenen Person ein.
      </p>

      <p>
        Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der
        Anschrift, E-Mail-Adresse oder Telefonnummer einer betroffenen Person,
        erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in
        &Uuml;bereinstimmung mit den f&uuml;r Hirsch Public Relations &ndash;
        Alexander Hirsch geltenden landesspezifischen Datenschutzbestimmungen.
        Mittels dieser Datenschutzerkl&auml;rung m&ouml;chte unser Unternehmen
        die &Ouml;ffentlichkeit &uuml;ber Art, Umfang und Zweck der von uns
        erhobenen, genutzten und verarbeiteten personenbezogenen Daten
        informieren. Ferner werden betroffene Personen mittels dieser
        Datenschutzerkl&auml;rung &uuml;ber die ihnen zustehenden Rechte
        aufgekl&auml;rt.
      </p>

      <p>
        Hirsch Public Relations &ndash; Alexander Hirsch hat als f&uuml;r die
        Verarbeitung Verantwortlicher zahlreiche technische und organisatorische
        Ma&szlig;nahmen umgesetzt, um einen m&ouml;glichst l&uuml;ckenlosen
        Schutz der &uuml;ber diese Internetseite verarbeiteten personenbezogenen
        Daten sicherzustellen. Dennoch k&ouml;nnen Internetbasierte
        Daten&uuml;bertragungen grunds&auml;tzlich Sicherheitsl&uuml;cken
        aufweisen, sodass ein absoluter Schutz nicht gew&auml;hrleistet werden
        kann. Aus diesem Grund steht es jeder betroffenen Person frei,
        personenbezogene Daten auch auf alternativen Wegen, beispielsweise
        telefonisch, an uns zu &uuml;bermitteln.
      </p>

      <p>1. Begriffsbestimmungen</p>

      <p>
        Die Datenschutzerkl&auml;rung von Hirsch Public Relations &ndash;
        Alexander Hirsch beruht auf den Begrifflichkeiten, die durch den
        Europ&auml;ischen Richtlinien- und Verordnungsgeber beim Erlass der
        Datenschutz-Grundverordnung (DS-GVO) verwendet wurden. Unsere
        Datenschutzerkl&auml;rung soll sowohl f&uuml;r die &Ouml;ffentlichkeit
        als auch f&uuml;r unsere Kunden und Gesch&auml;ftspartner einfach lesbar
        und verst&auml;ndlich sein. Um dies zu gew&auml;hrleisten, m&ouml;chten
        wir vorab die verwendeten Begrifflichkeiten erl&auml;utern.
      </p>

      <p>
        Wir verwenden in dieser Datenschutzerkl&auml;rung unter anderem die
        folgenden Begriffe:
      </p>

      <p>a) personenbezogene Daten</p>

      <p>
        Personenbezogene Daten sind alle Informationen, die sich auf eine
        identifizierte oder identifizierbare nat&uuml;rliche Person (im
        Folgenden &#8222;betroffene Person&ldquo;) beziehen. Als identifizierbar
        wird eine nat&uuml;rliche Person angesehen, die direkt oder indirekt,
        insbesondere mittels Zuordnung zu einer Kennung wie einem Namen, zu
        einer Kennnummer, zu Standortdaten, zu einer Online-Kennung oder zu
        einem oder mehreren besonderen Merkmalen, die Ausdruck der physischen,
        physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen
        oder sozialen Identit&auml;t dieser nat&uuml;rlichen Person sind,
        identifiziert werden kann.
      </p>

      <p>b) betroffene Person</p>

      <p>
        Betroffene Person ist jede identifizierte oder identifizierbare
        nat&uuml;rliche Person, deren personenbezogene Daten von dem f&uuml;r
        die Verarbeitung Verantwortlichen verarbeitet werden.
      </p>

      <p>c) Verarbeitung</p>

      <p>
        Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren
        ausgef&uuml;hrte Vorgang oder jede solche Vorgangsreihe im Zusammenhang
        mit personenbezogenen Daten wie das Erheben, das Erfassen, die
        Organisation, das Ordnen, die Speicherung, die Anpassung oder
        Ver&auml;nderung, das Auslesen, das Abfragen, die Verwendung, die
        Offenlegung durch &Uuml;bermittlung, Verbreitung oder eine andere Form
        der Bereitstellung, den Abgleich oder die Verkn&uuml;pfung, die
        Einschr&auml;nkung, das L&ouml;schen oder die Vernichtung.
      </p>

      <p>d) Einschr&auml;nkung der Verarbeitung</p>

      <p>
        Einschr&auml;nkung der Verarbeitung ist die Markierung gespeicherter
        personenbezogener Daten mit dem Ziel, ihre k&uuml;nftige Verarbeitung
        einzuschr&auml;nken.
      </p>

      <p>e) Profiling</p>

      <p>
        Profiling ist jede Art der automatisierten Verarbeitung
        personenbezogener Daten, die darin besteht, dass diese personenbezogenen
        Daten verwendet werden, um bestimmte pers&ouml;nliche Aspekte, die sich
        auf eine nat&uuml;rliche Person beziehen, zu bewerten, insbesondere, um
        Aspekte bez&uuml;glich Arbeitsleistung, wirtschaftlicher Lage,
        Gesundheit, pers&ouml;nlicher Vorlieben, Interessen,
        Zuverl&auml;ssigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser
        nat&uuml;rlichen Person zu analysieren oder vorherzusagen.
      </p>

      <p>f) Pseudonymisierung</p>

      <p>
        Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer
        Weise, auf welche die personenbezogenen Daten ohne Hinzuziehung
        zus&auml;tzlicher Informationen nicht mehr einer spezifischen
        betroffenen Person zugeordnet werden k&ouml;nnen, sofern diese
        zus&auml;tzlichen Informationen gesondert aufbewahrt werden und
        technischen und organisatorischen Ma&szlig;nahmen unterliegen, die
        gew&auml;hrleisten, dass die personenbezogenen Daten nicht einer
        identifizierten oder identifizierbaren nat&uuml;rlichen Person
        zugewiesen werden.
      </p>

      <p>g) Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher</p>

      <p>
        Verantwortlicher oder f&uuml;r die Verarbeitung Verantwortlicher ist die
        nat&uuml;rliche oder juristische Person, Beh&ouml;rde, Einrichtung oder
        andere Stelle, die allein oder gemeinsam mit anderen &uuml;ber die
        Zwecke und Mittel der Verarbeitung von personenbezogenen Daten
        entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das
        Unionsrecht oder das Recht der Mitgliedstaaten vorgegeben, so kann der
        Verantwortliche beziehungsweise k&ouml;nnen die bestimmten Kriterien
        seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
        vorgesehen werden.
      </p>

      <p>h) Auftragsverarbeiter</p>

      <p>
        Auftragsverarbeiter ist eine nat&uuml;rliche oder juristische Person,
        Beh&ouml;rde, Einrichtung oder andere Stelle, die personenbezogene Daten
        im Auftrag des Verantwortlichen verarbeitet.
      </p>

      <p>i) Empf&auml;nger</p>

      <p>
        Empf&auml;nger ist eine nat&uuml;rliche oder juristische Person,
        Beh&ouml;rde, Einrichtung oder andere Stelle, der personenbezogene Daten
        offengelegt werden, unabh&auml;ngig davon, ob es sich bei ihr um einen
        Dritten handelt oder nicht. Beh&ouml;rden, die im Rahmen eines
        bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der
        Mitgliedstaaten m&ouml;glicherweise personenbezogene Daten erhalten,
        gelten jedoch nicht als Empf&auml;nger.
      </p>

      <p>j) Dritter</p>

      <p>
        Dritter ist eine nat&uuml;rliche oder juristische Person, Beh&ouml;rde,
        Einrichtung oder andere Stelle au&szlig;er der betroffenen Person, dem
        Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter
        der unmittelbaren Verantwortung des Verantwortlichen oder des
        Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu
        verarbeiten.
      </p>

      <p>k) Einwilligung</p>

      <p>
        Einwilligung ist jede von der betroffenen Person freiwillig f&uuml;r den
        bestimmten Fall in informierter Weise und unmissverst&auml;ndlich
        abgegebene Willensbekundung in Form einer Erkl&auml;rung oder einer
        sonstigen eindeutigen best&auml;tigenden Handlung, mit der die
        betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der
        sie betreffenden personenbezogenen Daten einverstanden ist.
      </p>

      <p>
        2. Name und Anschrift des f&uuml;r die Verarbeitung Verantwortlichen
      </p>

      <p>
        Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in
        den Mitgliedstaaten der Europ&auml;ischen Union geltenden
        Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem
        Charakter ist die:
      </p>

      <p>Hirsch Public Relations &ndash; Alexander Hirsch</p>

      <p>
        Georg-Speyer-Str. 33-35
        <br />
        60487 Frankfurt am Main
      </p>

      <p>Deutschland</p>

      <p>Tel.: 069-34874174</p>

      <p>E-Mail: info@hirsch-pr.de</p>

      <p>Website: www.hirsch-pr.de</p>

      <p>3. Erfassung von allgemeinen Daten und Informationen</p>

      <p>
        Die Internetseite von Hirsch Public Relations &ndash; Alexander Hirsch
        erfasst mit jedem Aufruf der Internetseite durch eine betroffene Person
        oder ein automatisiertes System eine Reihe von allgemeinen Daten und
        Informationen. Diese allgemeinen Daten und Informationen werden in den
        Logfiles des Servers gespeichert. Erfasst werden k&ouml;nnen die (1)
        verwendeten Browsertypen und Versionen, (2) das vom zugreifenden System
        verwendete Betriebssystem, (3) die Internetseite, von welcher ein
        zugreifendes System auf unsere Internetseite gelangt (sogenannte
        Referrer), (4) die Unterwebseiten, welche &uuml;ber ein zugreifendes
        System auf unserer Internetseite angesteuert werden, (5) das Datum und
        die Uhrzeit eines Zugriffs auf die Internetseite, (6) eine
        Internet-Protokoll-Adresse (IP-Adresse), (7) der
        Internet-Service-Provider des zugreifenden Systems und (8) sonstige
        &auml;hnliche Daten und Informationen, die der Gefahrenabwehr im Falle
        von Angriffen auf unsere informationstechnologischen Systeme dienen.
      </p>

      <p>
        Bei der Nutzung dieser allgemeinen Daten und Informationen zieht Hirsch
        Public Relations &ndash; Alexander Hirsch keine R&uuml;ckschl&uuml;sse
        auf die betroffene Person. Diese Informationen werden vielmehr
        ben&ouml;tigt, um (1) die Inhalte unserer Internetseite korrekt
        auszuliefern, (2) die Inhalte unserer Internetseite sowie die Werbung
        f&uuml;r diese zu optimieren, (3) die dauerhafte Funktionsf&auml;higkeit
        unserer informationstechnologischen Systeme und der Technik unserer
        Internetseite zu gew&auml;hrleisten sowie (4) um
        Strafverfolgungsbeh&ouml;rden im Falle eines Cyberangriffes die zur
        Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym
        erhobenen Daten und Informationen werden durch Hirsch Public Relations
        &ndash; Alexander Hirsch daher einerseits statistisch und ferner mit dem
        Ziel ausgewertet, den Datenschutz und die Datensicherheit in unserem
        Unternehmen zu erh&ouml;hen, um letztlich ein optimales Schutzniveau
        f&uuml;r die von uns verarbeiteten personenbezogenen Daten
        sicherzustellen. Die anonymen Daten der Server-Logfiles werden getrennt
        von allen durch eine betroffene Person angegebenen personenbezogenen
        Daten gespeichert.
      </p>

      <p>
        4. Routinem&auml;&szlig;ige L&ouml;schung und Sperrung von
        personenbezogenen Daten
      </p>

      <p>
        Der f&uuml;r die Verarbeitung Verantwortliche verarbeitet und speichert
        personenbezogene Daten der betroffenen Person nur f&uuml;r den Zeitraum,
        der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern
        dies durch den Europ&auml;ischen Richtlinien- und Verordnungsgeber oder
        einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der
        f&uuml;r die Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.
      </p>

      <p>
        Entf&auml;llt der Speicherungszweck oder l&auml;uft eine vom
        Europ&auml;ischen Richtlinien- und Verordnungsgeber oder einem anderen
        zust&auml;ndigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden
        die personenbezogenen Daten routinem&auml;&szlig;ig und entsprechend den
        gesetzlichen Vorschriften gesperrt oder gel&ouml;scht.
      </p>

      <p>5. Rechte der betroffenen Person</p>

      <p>a) Recht auf Best&auml;tigung</p>

      <p>
        Jede betroffene Person hat das vom Europ&auml;ischen Richtlinien- und
        Verordnungsgeber einger&auml;umte Recht, von dem f&uuml;r die
        Verarbeitung Verantwortlichen eine Best&auml;tigung dar&uuml;ber zu
        verlangen, ob sie betreffende personenbezogene Daten verarbeitet werden.
        M&ouml;chte eine betroffene Person dieses Best&auml;tigungsrecht in
        Anspruch nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des
        f&uuml;r die Verarbeitung Verantwortlichen wenden.
      </p>

      <p>b) Recht auf Auskunft</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, jederzeit von dem f&uuml;r die Verarbeitung
        Verantwortlichen unentgeltliche Auskunft &uuml;ber die zu seiner Person
        gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu
        erhalten. Ferner hat der Europ&auml;ische Richtlinien- und
        Verordnungsgeber der betroffenen Person Auskunft &uuml;ber folgende
        Informationen zugestanden:
      </p>

      <p>
        die Verarbeitungszwecke die Kategorien personenbezogener Daten, die
        verarbeitet werden die Empf&auml;nger oder Kategorien von
        Empf&auml;ngern, gegen&uuml;ber denen die personenbezogenen Daten
        offengelegt worden sind oder noch offengelegt werden, insbesondere bei
        Empf&auml;ngern in Drittl&auml;ndern oder bei internationalen
        Organisationen falls m&ouml;glich die geplante Dauer, f&uuml;r die die
        personenbezogenen Daten gespeichert werden, oder, falls dies nicht
        m&ouml;glich ist, die Kriterien f&uuml;r die Festlegung dieser Dauer das
        Bestehen eines Rechts auf Berichtigung oder L&ouml;schung der sie
        betreffenden personenbezogenen Daten oder auf Einschr&auml;nkung der
        Verarbeitung durch den Verantwortlichen oder eines Widerspruchsrechts
        gegen diese Verarbeitung das Bestehen eines Beschwerderechts bei einer
        Aufsichtsbeh&ouml;rde wenn die personenbezogenen Daten nicht bei der
        betroffenen Person erhoben werden: Alle verf&uuml;gbaren Informationen
        &uuml;ber die Herkunft der Daten das Bestehen einer automatisierten
        Entscheidungsfindung einschlie&szlig;lich Profiling gem&auml;&szlig;
        Artikel 22 Abs.1 und 4 DS-GVO und &mdash; zumindest in diesen
        F&auml;llen &mdash; aussagekr&auml;ftige Informationen &uuml;ber die
        involvierte Logik sowie die Tragweite und die angestrebten Auswirkungen
        einer derartigen Verarbeitung f&uuml;r die betroffene Person Ferner
        steht der betroffenen Person ein Auskunftsrecht dar&uuml;ber zu, ob
        personenbezogene Daten an ein Drittland oder an eine internationale
        Organisation &uuml;bermittelt wurden. Sofern dies der Fall ist, so steht
        der betroffenen Person im &Uuml;brigen das Recht zu, Auskunft &uuml;ber
        die geeigneten Garantien im Zusammenhang mit der &Uuml;bermittlung zu
        erhalten.
      </p>

      <p>
        M&ouml;chte eine betroffene Person dieses Auskunftsrecht in Anspruch
        nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des f&uuml;r
        die Verarbeitung Verantwortlichen wenden.
      </p>

      <p>c) Recht auf Berichtigung</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, die unverz&uuml;gliche Berichtigung sie
        betreffender unrichtiger personenbezogener Daten zu verlangen. Ferner
        steht der betroffenen Person das Recht zu, unter Ber&uuml;cksichtigung
        der Zwecke der Verarbeitung, die Vervollst&auml;ndigung
        unvollst&auml;ndiger personenbezogener Daten &mdash; auch mittels einer
        erg&auml;nzenden Erkl&auml;rung &mdash; zu verlangen.
      </p>

      <p>
        M&ouml;chte eine betroffene Person dieses Berichtigungsrecht in Anspruch
        nehmen, kann sie sich hierzu jederzeit an einen Mitarbeiter des f&uuml;r
        die Verarbeitung Verantwortlichen wenden.
      </p>

      <p>d) Recht auf L&ouml;schung (Recht auf Vergessen werden)</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, von dem Verantwortlichen zu verlangen, dass die sie
        betreffenden personenbezogenen Daten unverz&uuml;glich gel&ouml;scht
        werden, sofern einer der folgenden Gr&uuml;nde zutrifft und soweit die
        Verarbeitung nicht erforderlich ist:
      </p>

      <p>
        Die personenbezogenen Daten wurden f&uuml;r solche Zwecke erhoben oder
        auf sonstige Weise verarbeitet, f&uuml;r welche sie nicht mehr notwendig
        sind. Die betroffene Person widerruft ihre Einwilligung, auf die sich
        die Verarbeitung gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe a DS-GVO oder
        Art. 9 Abs. 2 Buchstabe a DS-GVO st&uuml;tzte, und es fehlt an einer
        anderweitigen Rechtsgrundlage f&uuml;r die Verarbeitung. Die betroffene
        Person legt gem&auml;&szlig; Art. 21 Abs. 1 DS-GVO Widerspruch gegen die
        Verarbeitung ein, und es liegen keine vorrangigen berechtigten
        Gr&uuml;nde f&uuml;r die Verarbeitung vor, oder die betroffene Person
        legt gem&auml;&szlig; Art. 21 Abs. 2 DS-GVO Widerspruch gegen die
        Verarbeitung ein. Die personenbezogenen Daten wurden
        unrechtm&auml;&szlig;ig verarbeitet. Die L&ouml;schung der
        personenbezogenen Daten ist zur Erf&uuml;llung einer rechtlichen
        Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten
        erforderlich, dem der Verantwortliche unterliegt. Die personenbezogenen
        Daten wurden in Bezug auf angebotene Dienste der
        Informationsgesellschaft gem&auml;&szlig; Art. 8 Abs. 1 DS-GVO erhoben.
        Sofern einer der oben genannten Gr&uuml;nde zutrifft und eine betroffene
        Person die L&ouml;schung von personenbezogenen Daten, die bei Hirsch
        Public Relations &ndash; Alexander Hirsch gespeichert sind, veranlassen
        m&ouml;chte, kann sie sich hierzu jederzeit an einen Mitarbeiter des
        f&uuml;r die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter von
        Hirsch Public Relations &ndash; Alexander Hirsch wird veranlassen, dass
        dem L&ouml;schverlangen unverz&uuml;glich nachgekommen wird.
      </p>

      <p>
        Wurden die personenbezogenen Daten von Hirsch Public Relations &ndash;
        Alexander Hirsch &ouml;ffentlich gemacht und ist unser Unternehmen als
        Verantwortlicher gem&auml;&szlig; Art. 17 Abs. 1 DS-GVO zur
        L&ouml;schung der personenbezogenen Daten verpflichtet, so trifft Hirsch
        Public Relations &ndash; Alexander Hirsch unter Ber&uuml;cksichtigung
        der verf&uuml;gbaren Technologie und der Implementierungskosten
        angemessene Ma&szlig;nahmen, auch technischer Art, um andere f&uuml;r
        die Datenverarbeitung Verantwortliche, welche die ver&ouml;ffentlichten
        personenbezogenen Daten verarbeiten, dar&uuml;ber in Kenntnis zu setzen,
        dass die betroffene Person von diesen anderen f&uuml;r die
        Datenverarbeitung Verantwortlichen die L&ouml;schung s&auml;mtlicher
        Links zu diesen personenbezogenen Daten oder von Kopien oder
        Replikationen dieser personenbezogenen Daten verlangt hat, soweit die
        Verarbeitung nicht erforderlich ist. Der Mitarbeiter von Hirsch Public
        Relations &ndash; Alexander Hirsch wird im Einzelfall das Notwendige
        veranlassen.
      </p>

      <p>e) Recht auf Einschr&auml;nkung der Verarbeitung</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, von dem Verantwortlichen die Einschr&auml;nkung der
        Verarbeitung zu verlangen, wenn eine der folgenden Voraussetzungen
        gegeben ist:
      </p>

      <p>
        Die Richtigkeit der personenbezogenen Daten wird von der betroffenen
        Person bestritten, und zwar f&uuml;r eine Dauer, die es dem
        Verantwortlichen erm&ouml;glicht, die Richtigkeit der personenbezogenen
        Daten zu &uuml;berpr&uuml;fen. Die Verarbeitung ist
        unrechtm&auml;&szlig;ig, die betroffene Person lehnt die L&ouml;schung
        der personenbezogenen Daten ab und verlangt stattdessen die
        Einschr&auml;nkung der Nutzung der personenbezogenen Daten. Der
        Verantwortliche ben&ouml;tigt die personenbezogenen Daten f&uuml;r die
        Zwecke der Verarbeitung nicht l&auml;nger, die betroffene Person
        ben&ouml;tigt sie jedoch zur Geltendmachung, Aus&uuml;bung oder
        Verteidigung von Rechtsanspr&uuml;chen. Die betroffene Person hat
        Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt
        und es steht noch nicht fest, ob die berechtigten Gr&uuml;nde des
        Verantwortlichen gegen&uuml;ber denen der betroffenen Person
        &uuml;berwiegen. Sofern eine der oben genannten Voraussetzungen gegeben
        ist und eine betroffene Person die Einschr&auml;nkung von
        personenbezogenen Daten, die bei Hirsch Public Relations &ndash;
        Alexander Hirsch gespeichert sind, verlangen m&ouml;chte, kann sie sich
        hierzu jederzeit an einen Mitarbeiter des f&uuml;r die Verarbeitung
        Verantwortlichen wenden. Der Mitarbeiter von Hirsch Public Relations
        &ndash; Alexander Hirsch wird die Einschr&auml;nkung der Verarbeitung
        veranlassen.
      </p>

      <p>f) Recht auf Daten&uuml;bertragbarkeit</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, die sie betreffenden personenbezogenen Daten,
        welche durch die betroffene Person einem Verantwortlichen bereitgestellt
        wurden, in einem strukturierten, g&auml;ngigen und maschinenlesbaren
        Format zu erhalten. Sie hat au&szlig;erdem das Recht, diese Daten einem
        anderen Verantwortlichen ohne Behinderung durch den Verantwortlichen,
        dem die personenbezogenen Daten bereitgestellt wurden, zu
        &uuml;bermitteln, sofern die Verarbeitung auf der Einwilligung
        gem&auml;&szlig; Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2
        Buchstabe a DS-GVO oder auf einem Vertrag gem&auml;&szlig; Art. 6 Abs. 1
        Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter
        Verfahren erfolgt, sofern die Verarbeitung nicht f&uuml;r die
        Wahrnehmung einer Aufgabe erforderlich ist, die im &ouml;ffentlichen
        Interesse liegt oder in Aus&uuml;bung &ouml;ffentlicher Gewalt erfolgt,
        welche dem Verantwortlichen &uuml;bertragen wurde.
      </p>

      <p>
        Ferner hat die betroffene Person bei der Aus&uuml;bung ihres Rechts auf
        Daten&uuml;bertragbarkeit gem&auml;&szlig; Art. 20 Abs. 1 DS-GVO das
        Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem
        Verantwortlichen an einen anderen Verantwortlichen &uuml;bermittelt
        werden, soweit dies technisch machbar ist und sofern hiervon nicht die
        Rechte und Freiheiten anderer Personen beeintr&auml;chtigt werden.
      </p>

      <p>
        Zur Geltendmachung des Rechts auf Daten&uuml;bertragbarkeit kann sich
        die betroffene Person jederzeit an einen Mitarbeiter von Hirsch Public
        Relations &ndash; Alexander Hirsch wenden.
      </p>

      <p>g) Recht auf Widerspruch</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, aus Gr&uuml;nden, die sich aus ihrer besonderen
        Situation ergeben, jederzeit gegen die Verarbeitung sie betreffender
        personenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e
        oder f DS-GVO erfolgt, Widerspruch einzulegen. Dies gilt auch f&uuml;r
        ein auf diese Bestimmungen gest&uuml;tztes Profiling.
      </p>

      <p>
        Hirsch Public Relations &ndash; Alexander Hirsch verarbeitet die
        personenbezogenen Daten im Falle des Widerspruchs nicht mehr, es sei
        denn, wir k&ouml;nnen zwingende schutzw&uuml;rdige Gr&uuml;nde f&uuml;r
        die Verarbeitung nachweisen, die den Interessen, Rechten und Freiheiten
        der betroffenen Person &uuml;berwiegen, oder die Verarbeitung dient der
        Geltendmachung, Aus&uuml;bung oder Verteidigung von
        Rechtsanspr&uuml;chen.
      </p>

      <p>
        Verarbeitet Hirsch Public Relations &ndash; Alexander Hirsch
        personenbezogene Daten, um Direktwerbung zu betreiben, so hat die
        betroffene Person das Recht, jederzeit Widerspruch gegen die
        Verarbeitung der personenbezogenen Daten zum Zwecke derartiger Werbung
        einzulegen. Dies gilt auch f&uuml;r das Profiling, soweit es mit solcher
        Direktwerbung in Verbindung steht. Widerspricht die betroffene Person
        gegen&uuml;ber Hirsch Public Relations &ndash; Alexander Hirsch der
        Verarbeitung f&uuml;r Zwecke der Direktwerbung, so wird Hirsch Public
        Relations &ndash; Alexander Hirsch die personenbezogenen Daten nicht
        mehr f&uuml;r diese Zwecke verarbeiten.
      </p>

      <p>
        Zudem hat die betroffene Person das Recht, aus Gr&uuml;nden, die sich
        aus ihrer besonderen Situation ergeben, gegen die sie betreffende
        Verarbeitung personenbezogener Daten, die bei Hirsch Public Relations
        &ndash; Alexander Hirsch zu wissenschaftlichen oder historischen
        Forschungszwecken oder zu statistischen Zwecken gem&auml;&szlig; Art. 89
        Abs. 1 DS-GVO erfolgen, Widerspruch einzulegen, es sei denn, eine solche
        Verarbeitung ist zur Erf&uuml;llung einer im &ouml;ffentlichen Interesse
        liegenden Aufgabe erforderlich.
      </p>

      <p>
        Zur Aus&uuml;bung des Rechts auf Widerspruch kann sich die betroffene
        Person direkt jeden Mitarbeiter von Hirsch Public Relations &ndash;
        Alexander Hirsch oder einen anderen Mitarbeiter wenden. Der betroffenen
        Person steht es ferner frei, im Zusammenhang mit der Nutzung von
        Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
        2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren
        auszu&uuml;ben, bei denen technische Spezifikationen verwendet werden.
      </p>

      <p>
        h) Automatisierte Entscheidungen im Einzelfall einschlie&szlig;lich
        Profiling
      </p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, nicht einer ausschlie&szlig;lich auf einer
        automatisierten Verarbeitung &mdash; einschlie&szlig;lich Profiling
        &mdash; beruhenden Entscheidung unterworfen zu werden, die ihr
        gegen&uuml;ber rechtliche Wirkung entfaltet oder sie in &auml;hnlicher
        Weise erheblich beeintr&auml;chtigt, sofern die Entscheidung (1) nicht
        f&uuml;r den Abschluss oder die Erf&uuml;llung eines Vertrags zwischen
        der betroffenen Person und dem Verantwortlichen erforderlich ist, oder
        (2) aufgrund von Rechtsvorschriften der Union oder der Mitgliedstaaten,
        denen der Verantwortliche unterliegt, zul&auml;ssig ist und diese
        Rechtsvorschriften angemessene Ma&szlig;nahmen zur Wahrung der Rechte
        und Freiheiten sowie der berechtigten Interessen der betroffenen Person
        enthalten oder (3) mit ausdr&uuml;cklicher Einwilligung der betroffenen
        Person erfolgt.
      </p>

      <p>
        Ist die Entscheidung (1) f&uuml;r den Abschluss oder die Erf&uuml;llung
        eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen
        erforderlich oder (2) erfolgt sie mit ausdr&uuml;cklicher Einwilligung
        der betroffenen Person, trifft Hirsch Public Relations &ndash; Alexander
        Hirsch angemessene Ma&szlig;nahmen, um die Rechte und Freiheiten sowie
        die berechtigten Interessen der betroffenen Person zu wahren, wozu
        mindestens das Recht auf Erwirkung des Eingreifens einer Person seitens
        des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf
        Anfechtung der Entscheidung geh&ouml;rt.
      </p>

      <p>
        M&ouml;chte die betroffene Person Rechte mit Bezug auf automatisierte
        Entscheidungen geltend machen, kann sie sich hierzu jederzeit an einen
        Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen wenden.
      </p>

      <p>i) Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</p>

      <p>
        Jede von der Verarbeitung personenbezogener Daten betroffene Person hat
        das vom Europ&auml;ischen Richtlinien- und Verordnungsgeber
        gew&auml;hrte Recht, eine Einwilligung zur Verarbeitung
        personenbezogener Daten jederzeit zu widerrufen.
      </p>

      <p>
        M&ouml;chte die betroffene Person ihr Recht auf Widerruf einer
        Einwilligung geltend machen, kann sie sich hierzu jederzeit an einen
        Mitarbeiter des f&uuml;r die Verarbeitung Verantwortlichen wenden.
      </p>

      <p>6. Datenschutz bei Bewerbungen und im Bewerbungsverfahren</p>

      <p>
        Der f&uuml;r die Verarbeitung Verantwortliche erhebt und verarbeitet die
        personenbezogenen Daten von Bewerbern zum Zwecke der Abwicklung des
        Bewerbungsverfahrens. Die Verarbeitung kann auch auf elektronischem Wege
        erfolgen. Dies ist insbesondere dann der Fall, wenn ein Bewerber
        entsprechende Bewerbungsunterlagen auf dem elektronischen Wege,
        beispielsweise per E-Mail oder &uuml;ber ein auf der Internetseite
        befindliches Webformular, an den f&uuml;r die Verarbeitung
        Verantwortlichen &uuml;bermittelt. Schlie&szlig;t der f&uuml;r die
        Verarbeitung Verantwortliche einen Anstellungsvertrag mit einem
        Bewerber, werden die &uuml;bermittelten Daten zum Zwecke der Abwicklung
        des Besch&auml;ftigungsverh&auml;ltnisses unter Beachtung der
        gesetzlichen Vorschriften gespeichert. Wird von dem f&uuml;r die
        Verarbeitung Verantwortlichen kein Anstellungsvertrag mit dem Bewerber
        geschlossen, so werden die Bewerbungsunterlagen zwei Monate nach
        Bekanntgabe der Absageentscheidung automatisch gel&ouml;scht, sofern
        einer L&ouml;schung keine sonstigen berechtigten Interessen des f&uuml;r
        die Verarbeitung Verantwortlichen entgegenstehen. Sonstiges berechtigtes
        Interesse in diesem Sinne ist beispielsweise eine Beweispflicht in einem
        Verfahren nach dem Allgemeinen Gleichbehandlungsgesetz (AGG).
      </p>

      <p>7. Rechtsgrundlage der Verarbeitung</p>

      <p>
        Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage
        f&uuml;r Verarbeitungsvorg&auml;nge, bei denen wir eine Einwilligung
        f&uuml;r einen bestimmten Verarbeitungszweck einholen. Ist die
        Verarbeitung personenbezogener Daten zur Erf&uuml;llung eines Vertrags,
        dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies
        beispielsweise bei Verarbeitungsvorg&auml;ngen der Fall ist, die
        f&uuml;r eine Lieferung von Waren oder die Erbringung einer sonstigen
        Leistung oder Gegenleistung notwendig sind, so beruht die Verarbeitung
        auf Art. 6 I lit. b DS-GVO. Gleiches gilt f&uuml;r solche
        Verarbeitungsvorg&auml;nge die zur Durchf&uuml;hrung vorvertraglicher
        Ma&szlig;nahmen erforderlich sind, etwa in F&auml;llen von Anfragen zur
        unseren Produkten oder Leistungen. Unterliegt unser Unternehmen einer
        rechtlichen Verpflichtung durch welche eine Verarbeitung von
        personenbezogenen Daten erforderlich wird, wie beispielsweise zur
        Erf&uuml;llung steuerlicher Pflichten, so basiert die Verarbeitung auf
        Art. 6 I lit. c DS-GVO. In seltenen F&auml;llen k&ouml;nnte die
        Verarbeitung von personenbezogenen Daten erforderlich werden, um
        lebenswichtige Interessen der betroffenen Person oder einer anderen
        nat&uuml;rlichen Person zu sch&uuml;tzen. Dies w&auml;re beispielsweise
        der Fall, wenn ein Besucher in unserem Betrieb verletzt werden
        w&uuml;rde und daraufhin sein Name, sein Alter, seine Krankenkassendaten
        oder sonstige lebenswichtige Informationen an einen Arzt, ein
        Krankenhaus oder sonstige Dritte weitergegeben werden m&uuml;ssten. Dann
        w&uuml;rde die Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen.
        Letztlich k&ouml;nnten Verarbeitungsvorg&auml;nge auf Art. 6 I lit. f
        DS-GVO beruhen. Auf dieser Rechtsgrundlage basieren
        Verarbeitungsvorg&auml;nge, die von keiner der vorgenannten
        Rechtsgrundlagen erfasst werden, wenn die Verarbeitung zur Wahrung eines
        berechtigten Interesses unseres Unternehmens oder eines Dritten
        erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten
        des Betroffenen nicht &uuml;berwiegen. Solche Verarbeitungsvorg&auml;nge
        sind uns insbesondere deshalb gestattet, weil sie durch den
        Europ&auml;ischen Gesetzgeber besonders erw&auml;hnt wurden. Er vertrat
        insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
        k&ouml;nnte, wenn die betroffene Person ein Kunde des Verantwortlichen
        ist (Erw&auml;gungsgrund 47 Satz 2 DS-GVO).
      </p>

      <p>
        8. Berechtigte Interessen an der Verarbeitung, die von dem
        Verantwortlichen oder einem Dritten verfolgt werden
      </p>

      <p>
        Basiert die Verarbeitung personenbezogener Daten auf Artikel 6 I lit. f
        DS-GVO ist unser berechtigtes Interesse die Durchf&uuml;hrung unserer
        Gesch&auml;ftst&auml;tigkeit zugunsten des Wohlergehens all unserer
        Mitarbeiter und unserer Anteilseigner.
      </p>

      <p>
        9. Dauer, f&uuml;r die die personenbezogenen Daten gespeichert werden
      </p>

      <p>
        Das Kriterium f&uuml;r die Dauer der Speicherung von personenbezogenen
        Daten ist die jeweilige gesetzliche Aufbewahrungsfrist. Nach Ablauf der
        Frist werden die entsprechenden Daten routinem&auml;&szlig;ig
        gel&ouml;scht, sofern sie nicht mehr zur Vertragserf&uuml;llung oder
        Vertragsanbahnung erforderlich sind.
      </p>

      <p>
        10. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der
        personenbezogenen Daten; Erforderlichkeit f&uuml;r den
        Vertragsabschluss; Verpflichtung der betroffenen Person, die
        personenbezogenen Daten bereitzustellen; m&ouml;gliche Folgen der
        Nichtbereitstellung
      </p>

      <p>
        Wir kl&auml;ren Sie dar&uuml;ber auf, dass die Bereitstellung
        personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist (z.B.
        Steuervorschriften) oder sich auch aus vertraglichen Regelungen (z.B.
        Angaben zum Vertragspartner) ergeben kann. Mitunter kann es zu einem
        Vertragsschluss erforderlich sein, dass eine betroffene Person uns
        personenbezogene Daten zur Verf&uuml;gung stellt, die in der Folge durch
        uns verarbeitet werden m&uuml;ssen. Die betroffene Person ist
        beispielsweise verpflichtet uns personenbezogene Daten bereitzustellen,
        wenn unser Unternehmen mit ihr einen Vertrag abschlie&szlig;t. Eine
        Nichtbereitstellung der personenbezogenen Daten h&auml;tte zur Folge,
        dass der Vertrag mit dem Betroffenen nicht geschlossen werden
        k&ouml;nnte. Vor einer Bereitstellung personenbezogener Daten durch den
        Betroffenen muss sich der Betroffene an einen unserer Mitarbeiter
        wenden. Unser Mitarbeiter kl&auml;rt den Betroffenen einzelfallbezogen
        dar&uuml;ber auf, ob die Bereitstellung der personenbezogenen Daten
        gesetzlich oder vertraglich vorgeschrieben oder f&uuml;r den
        Vertragsabschluss erforderlich ist, ob eine Verpflichtung besteht, die
        personenbezogenen Daten bereitzustellen, und welche Folgen die
        Nichtbereitstellung der personenbezogenen Daten h&auml;tte.
      </p>

      <p>11. Bestehen einer automatisierten Entscheidungsfindung</p>

      <p>
        Als verantwortungsbewusstes Unternehmen verzichten wir auf eine
        automatische Entscheidungsfindung oder ein Profiling.
      </p>

      <p>
        Diese Datenschutzerkl&auml;rung wurde durch den
        Datenschutzerkl&auml;rungs-Generator der DGD Deutsche Gesellschaft
        f&uuml;r Datenschutz GmbH, die als Externer Datenschutzbeauftragter
        Straubing t&auml;tig ist, in Kooperation mit dem Anwalt f&uuml;r
        Datenschutzrecht Christian Solmecke erstellt.
      </p>
    </article>
  </LayoutLegal>
);

export default IndexPage;
